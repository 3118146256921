import * as React from "react";

function Pick2(props) {
  return (
    <svg
      width={300}
      height={300}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_885_7530)">
        <circle
          cx={144.268}
          cy={149.67}
          r={118.768}
          fill="#1C1A1B"
          stroke="#ABA9A7"
        />
        <path
          d="M65.227 219.274l29.738 5.644 36.611-1.559 22.491 18.974 27.143 8.443 36.454-8.443s-38.462 30.487-86.088 24.842c-51.29-6.08-66.35-47.901-66.35-47.901z"
          fill="#7D0E03"
        />
        <path
          d="M97.454 235.76l23.056-12.066 10.428-.802 22.337 19.104-3.371 11.458-22.895-20.649-29.555 2.955z"
          fill="#951D09"
        />
        <path
          d="M81.433 238.298l-3.24-3.805 19.928 1.457 28.596-2.895-1.512 3.561-27.864 2.272-15.908-.59z"
          fill="#CC3B1E"
        />
        <path
          d="M65.227 219.274l29.738 5.644 36.611-1.559 22.49 18.974 27.144 8.443 37.16-8.443-86.794 25.9-66.35-48.959z"
          fill="#7D0E03"
        />
        <path
          d="M97.454 235.76l23.056-12.066 10.428-.803 22.337 19.105-3.371 11.458-22.895-20.649-29.555 2.955z"
          fill="#951D09"
        />
        <path
          d="M81.433 238.297l-3.241-3.804 19.93 1.457 28.595-2.896-1.512 3.561-27.864 2.272-15.908-.59z"
          fill="#CC3B1E"
        />
        <path
          d="M83.428 240.639l29.962 14.123 26.644 10.296h31.052s-13.469 2.8-22.23 3.176c-18.719.802-47.284-8.117-47.284-8.117l-18.144-19.478z"
          fill="#30180E"
        />
        <path
          d="M113.411 254.491l11.94-18 13.271 31.037h-9.527l-15.684-13.037zM155.566 267.065l-10.945.814 8.735-25.195 14.965 15.787-12.755 8.594zM170.027 265.41l11.455-7.796 23.479-6.672-14.115 8.116-20.819 6.352z"
          fill="#E34C2D"
        />
        <path
          d="M112.804 255.261l15.033 10.679s-2.684.183-4.388 0c-2.409-.259-5.998-1.588-5.998-1.588l-4.647-9.091zM181.673 251.649l-9.528 12.703-16.584 2.823 26.112-15.526zM68.03 217.079l14.197 3.226 4.732 3.442 2.581 16.24-5.593 3.657-2.258-17.101-13.66-9.464z"
          fill="#951D09"
        />
        <path
          d="M60.286 212.347v-2.797l7.96 7.852 13.228 9.141-1.936.968-12.691-9.142-6.56-6.022z"
          fill="#CC3B1E"
        />
        <path
          d="M68.03 230.845l11.615-3.334-7.206 23.016-4.41-19.682zM72.87 250.527l16.456-10.217.645 12.153-17.1-1.936z"
          fill="#E34C2D"
        />
        <path
          d="M93.679 256.845l10.378-.787 12.838 8.682-23.216-7.895z"
          fill="#7D0E03"
        />
        <path
          d="M61.718 209.657l5.055 20.005-8.604 1.613 1.775-10.809 1.774-10.809zM68.03 217.079l14.197 3.227 4.732 3.441 2.581 16.241-5.593 3.656-2.258-17.1-13.66-9.465z"
          fill="#951D09"
        />
        <path
          d="M60.286 212.346v-2.797l7.96 7.852 13.228 9.141-1.936.968-12.691-9.141-6.56-6.023z"
          fill="#CC3B1E"
        />
        <path
          d="M60.286 214.066l7.636 16.886 14.242 15.402 10.939 11.291-28.935-20.819-3.882-22.76z"
          fill="#30180E"
        />
        <path
          d="M68.03 230.846l11.615-3.334-7.206 23.016-4.41-19.682zM72.87 250.527l16.456-10.217.645 12.153-17.1-1.936zM89.927 254.676l9.572-4.087 14.95 4.087H89.927z"
          fill="#E34C2D"
        />
        <path
          d="M27.47 129.557l18.625 6.997 28.88 22.555 4.815 29.028 15.187 24.03 37.294 13.072s-79.625 1.173-98.802-34.989c-12.35-23.289-5.999-60.693-5.999-60.693z"
          fill="#7D0E03"
        />
        <path
          d="M40.958 146.421l25.375 5.767 8.459 6.152 4.613 29.027-9.996 6.536-4.037-30.565-24.414-16.917z"
          fill="#951D09"
        />
        <path
          d="M27.117 137.963v-4.998l14.226 14.034 23.645 16.34-3.46 1.73-22.684-16.34-11.727-10.766z"
          fill="#CC3B1E"
        />
        <path
          d="M27.117 141.039l13.65 30.181 8.458 35.756 24.798 18.263-37.025-25.462-9.88-58.738z"
          fill="#30180E"
        />
        <path
          d="M40.958 171.027l20.762-5.959-12.88 41.139-7.882-35.18zM49.61 206.207l29.411-18.262 1.154 21.723-30.566-3.461zM73.64 224.855l17.109-7.305 26.72 7.305h-43.83z"
          fill="#E34C2D"
        />
        <path
          d="M39.996 171.221l9.035 35.756-9.916-3.318.881-32.438zM80.364 210.052l-6.92 14.61-23.646-17.685 30.566 3.075z"
          fill="#951D09"
        />
        <path
          d="M27.823 128.852l18.272 7.702 28.88 22.556 4.815 29.027 15.187 24.03 37.294 13.072-92.45-21.227-11.998-75.16z"
          fill="#7D0E03"
        />
        <path
          d="M40.958 146.421l25.375 5.767 8.459 6.152 4.613 29.028-9.996 6.536-4.037-30.566-24.414-16.917z"
          fill="#951D09"
        />
        <path
          d="M27.117 137.963v-4.998l14.226 14.034 23.645 16.34-3.46 1.73-22.684-16.34-11.727-10.766z"
          fill="#CC3B1E"
        />
        <path
          d="M27.117 141.039l13.65 30.181 8.458 35.756 24.798 18.263s-22.205-8.666-35.967-29.343c-15.063-22.633-10.939-54.857-10.939-54.857z"
          fill="#30180E"
        />
        <path
          d="M40.958 171.028l20.762-5.96-12.88 41.139-7.882-35.179zM49.609 206.208l29.412-18.263 1.154 21.723-30.566-3.46zM73.64 224.855l17.108-7.305 26.721 7.305h-43.83z"
          fill="#E34C2D"
        />
        <path
          d="M39.996 171.221l9.035 35.756-4.624 5.151-4.411-40.907zM80.364 210.052l-6.92 14.61-23.646-17.685 30.566 3.075z"
          fill="#951D09"
        />
        <path
          d="M27.117 177.899l10.618 13.193 16.157 12.619 2.694 16.24 12.17 8.408 17.192 12.35s-23.897-4.235-37.306-23.289c-10.114-14.373-21.525-39.521-21.525-39.521z"
          fill="#7D0E03"
        />
        <path
          d="M34.86 196.612l14.197 3.227 4.733 3.441 2.58 16.241-5.592 3.656-2.258-17.1-13.66-9.465z"
          fill="#951D09"
        />
        <path
          d="M40.526 201.161v-2.796l7.959 7.851 13.229 9.142-1.936.968-12.691-9.142-6.561-6.023z"
          fill="#CC3B1E"
        />
        <path
          d="M34.174 154.415l11.616-3.334-7.206 23.016-4.41-19.682z"
          fill="#E34C2D"
        />
        <path
          d="M62.424 206.834l5.055 20.005-8.604 1.613 3.55-21.618zM73.153 231.137l-3.872 8.174-13.229-9.894 17.101 1.72zM34.86 140.966l14.197 3.227 4.733 3.441 2.58 16.241-5.592 3.656-2.258-17.1-13.66-9.465z"
          fill="#951D09"
        />
        <path
          d="M27.117 136.234v-2.796l7.959 7.851 13.229 9.142-1.936.968-12.691-9.142-6.56-6.023z"
          fill="#CC3B1E"
        />
        <path
          d="M60.992 160.874l11.616-3.334-7.206 23.016-4.41-19.682zM52.17 167.42l3.986-3.222v8.162l-3.986-4.94z"
          fill="#E34C2D"
        />
        <path
          d="M34.323 154.84l5.054 20.005-8.604 1.613 3.55-21.618z"
          fill="#951D09"
        />
        <path
          d="M276.442 60.891c0 19.96-16.18 36.139-36.139 36.139-19.959 0-36.138-16.18-36.138-36.139 0-19.958 16.179-36.138 36.138-36.138s36.139 16.18 36.139 36.138z"
          fill="#262425"
        />
        <circle cx={240.303} cy={60.891} r={33.593} fill="#FCECDD" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280.713 96.305c-4.033 4.722-26.062-7.474-49.205-27.24-23.142-19.765-38.633-39.615-34.601-44.337 2.304-2.697 10.481.127 21.385 6.73l-1.129.806c-8.766-5.284-15.126-7.567-16.853-5.545-3.29 3.852 11.482 21.869 32.995 40.243 21.512 18.373 41.619 30.145 44.909 26.294 1.336-1.566-.309-5.47-4.177-10.786l1.306-.391c5.035 6.89 7.189 12.097 5.37 14.227z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M294.83 56.777c.488 6.19-23.713 13.144-54.054 15.531-30.34 2.388-55.331-.694-55.818-6.884-.278-3.537 7.501-7.322 19.88-10.363l-.228 1.368c-9.934 2.463-16.046 5.346-15.838 7.997.398 5.05 23.584 7.344 51.787 5.124 28.204-2.22 50.746-8.112 50.348-13.162-.161-2.052-4.085-3.649-10.579-4.673l.646-1.2c8.432 1.31 13.637 3.47 13.856 6.262z"
          fill="#fff"
        />
        <path
          d="M237.171 62.855l-2.319-7.462.83-.258 2.319 7.462-.83.258zM241.772 58.67l-1.898.59.781 2.512-.83.258-2.319-7.462 2.728-.847c.812-.253 1.474-.242 1.985.033.511.275.893.818 1.145 1.63.527 1.697-.003 2.792-1.592 3.286zm-2.126-.144l1.887-.586c1.028-.32 1.355-1.083.979-2.29-.179-.576-.431-.958-.758-1.148-.33-.198-.764-.213-1.303-.045l-1.887.587 1.082 3.482zM246.217 60.044l-2.32-7.462 4.465-1.388.228.734-3.634 1.129.904 2.911 3.074-.955.227.734-3.073.955.959 3.084-.83.258zM251.719 50.825c-1.079.335-1.492.909-1.239 1.721.141.453.36.728.658.825.303.087.859.1 1.667.037.813-.071 1.421-.02 1.822.155.399.168.694.56.887 1.18.475 1.53-.081 2.543-1.67 3.036-.524.163-1.201.307-2.03.43l-.405.067-.128-.717c1.018-.174 1.782-.34 2.292-.5 1.064-.33 1.441-.995 1.13-1.994-.125-.403-.333-.654-.624-.752-.285-.108-.767-.124-1.445-.048-.897.082-1.565.037-2.004-.133-.442-.178-.767-.602-.975-1.27-.415-1.337.179-2.255 1.782-2.753.524-.163 1.17-.305 1.937-.425l.383-.06.143.724c-1.037.189-1.765.348-2.181.477z"
          fill="#4A4A4A"
        />
        <path
          d="M137.592 132.156s36.345-23.344 46.837-8.498c9.985 14.13-18.836 41.215-18.836 41.215L84.72 225.486l-25.773-34.387 78.645-58.943z"
          fill="#FCECDD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M136.654 130.696l.937 1.46-78.644 58.943 25.772 34.387 80.874-60.613 1.188 1.265-.07.066-82.34 61.712-27.854-37.165 80.084-60.021.053-.034zm28.939 34.177s28.821-27.085 18.835-41.215c-10.491-14.846-46.837 8.498-46.837 8.498-.937-1.46-.937-1.46-.936-1.461l.008-.005.022-.014.083-.053.317-.199c.276-.171.679-.42 1.195-.73a128 128 0 014.32-2.475c3.612-1.979 8.558-4.472 13.855-6.5 5.273-2.018 11.017-3.625 16.193-3.731 5.191-.108 10.113 1.303 13.198 5.668 2.918 4.13 2.895 9.086 1.52 13.881-1.374 4.792-4.162 9.684-7.188 14.02-3.038 4.355-6.384 8.245-8.962 11.04-1.291 1.4-2.395 2.53-3.177 3.312-.392.391-.703.696-.918.904l-.248.238-.066.063-.018.017-.005.004-.002.002s-.001.001-1.189-1.264z"
          fill="#000"
        />
        <circle
          cx={152.389}
          cy={147.918}
          r={14.324}
          transform="rotate(53.149 152.389 147.918)"
          fill="#000"
        />
        <circle
          cx={152.387}
          cy={147.918}
          r={10.345}
          transform="rotate(53.149 152.387 147.918)"
          fill="#A3A3A3"
        />
        <path
          d="M70.17 182.189l39.162-29.352-43.213 4.045-32.795 24.579 1.193 1.592 30.88-7.232 4.772 6.368zM96.66 217.533l39.163-29.353-16.009 40.342-32.795 24.58-1.193-1.592 15.607-27.609-4.773-6.368z"
          fill="#000"
        />
        <path
          fill="#000"
          d="M62.611 179.402l34.364 45.85-1.274.954-34.364-45.85zM119.921 136.447l34.364 45.85-1.274.955-34.364-45.85z"
        />
        <path
          fill="#951D09"
          d="M213.36 103.214l-28.019 21-.955-1.274 28.02-21z"
        />
        <path
          fill="#000"
          d="M57.836 180.992l36.273 48.397-1.274.954-36.273-48.396zM115.147 138.038l36.273 48.397-1.274.955-36.273-48.397zM61.013 194.521l21 28.019-3.024 1.272-20.046-26.745 2.07-2.546z"
        />
        <path
          d="M61.33 200.252l15.273 20.378-2.894 1.445-14.579-19.451 2.2-2.372z"
          fill="#951D09"
        />
        <path
          fill="#7D0E03"
          d="M65.633 186.087l25.772 34.387-2.547 1.91-25.772-34.388zM122.945 143.133l25.772 34.387-2.547 1.909-25.772-34.387z"
        />
        <path
          fill="#000"
          d="M118.327 151.568l21 28.019-43.303 32.455-21-28.02z"
        />
        <path
          fill="#FCECDD"
          d="M117.689 156.024l17.182 22.925L97.3 207.108l-17.182-22.925z"
        />
        <circle
          cx={152.386}
          cy={147.961}
          r={11.081}
          transform="rotate(-15.775 152.386 147.961)"
          fill="#ED5840"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M156.493 158.343a11.073 11.073 0 01-11.503-2.067c1.531-1.55 2.6-2.913 2.667-6.607.438.51 1.029.839 1.618.982.711.175 1.51.008 2.091-.417.29-.211.529-.497.641-.852.109-.353.082-.768-.108-1.179l-1.586-2.976c-.156-.294-.572-.151-.359.309l1.36 2.937c.135.291.139.517.078.715a1.057 1.057 0 01-.407.526c-.406.297-1.06.431-1.556.31-.926-.244-1.718-1.009-1.878-2.044-.203-1.326-.105-2.314.816-3.628a4.18 4.18 0 011.841-1.461c.32-.132.671-.23 1.046-.286 1.119-.164 2.428.073 3.644 1.061l.275.228c.75.629 1.028.863 2.772.559.706-.123 1.27.286 1.356 1.271.073.843.128 1.803-1.254 2.712l-2.08 1.5c-.767.552-.988 1.278-.777 2.018.781 2.751 1.016 4.395 1.217 5.794l.086.595z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.109 207.828l2.367-1.724 2.595.298 1.701-.95 1.412 1.284-.028.02 12.539 1.437-2.805 2.043-12.607-1.445-2.764 1.959 5.87 8.058-2.806 2.044-6.052-8.31-3.443-1.125 1.865-1.041-1.173-1.61 3.329-.938zm1.447.536l-.551.308-.294-.405.845.097z"
          fill="#FCECDD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.109 207.828l2.367-1.724 2.595.298 1.701-.95 1.412 1.284-.028.02 12.539 1.437-2.805 2.043-12.607-1.445-2.764 1.959 5.87 8.058-2.806 2.044-6.052-8.31-3.443-1.125 1.865-1.041-1.173-1.61 3.329-.938zm-2.161 1.342l2.114-.595.741 1.018 3.01-1.681-2.265-.259 1.121-.816 2.547.292 1.457-.813.387.351-.818.579 12.575 1.442-1.12.816-12.602-1.445-3.567 2.528 5.875 8.066-1.665 1.213-5.771-7.924-2.127-.695 1.152-.643-1.044-1.434zm2.763-.903l.294.405.551-.308-.845-.097z"
          fill="#30180E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.188 243.677l13.769 3.184-34.758 25.316s-7.593-22.215 23.424-44.807c.84-.611 17.239-8.428 17.239-8.428l12.406-5.567-4.523 2.633s-2.018 2.616-2.172 4.224c-.139 1.451 1.151 2.754 1.494 3.072l11.186 1.236-3.117 2.27-10.88-1.201c-.387.33-.739.654-1.002.937-.417.45-.76 1.01-1.026 1.537l4.261 5.85-2.494 1.817-3.983-5.47-.962.319-1.606 8.932-1.557 1.464.075-.88 1.993-9.709s-2.38-8.836-14.227-.208c-11.845 8.628-3.54 13.479-3.54 13.479z"
          fill="#FCECDD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.832 244.287l.356-.61 13.769 3.184-34.758 25.316s-.086-.251-.202-.726c-.975-3.999-4.095-23.889 23.626-44.081.84-.611 17.239-8.428 17.239-8.428l7.388-3.316 5.018-2.251-4.523 2.633s-.072.093-.189.255c-.505.695-1.858 2.664-1.983 3.969-.139 1.451 1.151 2.754 1.494 3.072l11.186 1.236-3.117 2.27-10.88-1.201c-.387.33-.739.654-1.002.937-.417.45-.76 1.01-1.026 1.537l4.261 5.85-2.494 1.817-3.983-5.47-.962.319-1.606 8.932-1.557 1.464.075-.88 1.993-9.709.433-.117-.433.117s-2.38-8.836-14.227-.208c-11.845 8.628-3.54 13.479-3.54 13.479-.356.61-.357.609-.357.609l-.002-.001-.002-.001-.008-.005-.025-.015-.081-.052a7.624 7.624 0 01-1.135-.927c-.648-.644-1.405-1.626-1.745-2.935-.706-2.714.47-6.345 6.48-10.723 6.008-4.376 9.875-4.506 12.308-3.18 1.184.644 1.934 1.59 2.385 2.355a6.954 6.954 0 01.565 1.212l1.708-.566 3.871 5.316 1.353-.986-4.099-5.628.194-.385c.282-.558.66-1.182 1.139-1.699.289-.312.666-.657 1.06-.994l.233-.198 10.911 1.205 1.425-1.038-9.609-1.061-.17-.159a6.602 6.602 0 01-1.043-1.249c-.387-.609-.764-1.458-.673-2.407.049-.514.239-1.06.462-1.559a13.2 13.2 0 01.782-1.457c.07-.115.14-.227.209-.334l-6.165 2.767-.699.334-1.868.893c-1.55.742-3.626 1.738-5.728 2.753a752.65 752.65 0 00-5.88 2.866c-.827.409-1.532.762-2.056 1.032-.262.135-.476.247-.635.334a5.222 5.222 0 00-.254.146v.001c-15.39 11.208-21.137 22.272-23.072 30.467-.97 4.105-.988 7.51-.767 9.879a21.73 21.73 0 00.417 2.713l32.675-23.8-12.368-2.859-.093-.054z"
          fill="#30180E"
        />
        <path
          d="M70.058 243.993l2.805-2.043 25.495 3.561-2.806 2.043-25.494-3.561z"
          fill="#FCECDD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.23 244.451l4.448-3.24 27.507 3.842-4.448 3.24-27.506-3.842zm1.827-.458l25.495 3.561 2.806-2.043-25.495-3.561-2.806 2.043z"
          fill="#30180E"
        />
        <path
          d="M105.597 218.768l-2.806 2.044-4.745 24.926 2.806-2.043 4.745-24.927z"
          fill="#FCECDD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M106.624 217.146l-4.474 3.258-5.131 26.954 4.474-3.258 5.131-26.954zm-1.027 1.621l-4.745 24.927-2.806 2.043 4.745-24.926 2.806-2.044z"
          fill="#30180E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M145.927 194.549l-2.494 1.817.867 1.191-1.112.767 1.888.297 4.173 5.73 2.494-1.817-3.923-5.385 1.988-1.402 13.465 1.82 2.728-1.987-13.39-1.81.094-.066-.943-1.295-1.511 1.042-1.846-.249-2.26 1.646-.218-.299zm.544.747l.37.508.555-.383-.925-.125z"
          fill="#FCECDD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.433 196.366l.867 1.191-1.112.767 1.888.297 4.173 5.73 2.494-1.817-3.923-5.385 1.988-1.402 13.465 1.82 2.728-1.987-13.39-1.81.094-.066-.943-1.295-1.511 1.042-1.846-.249-2.26 1.646-.218-.299-2.494 1.817zm2.339-.831l-1.353.986.878 1.205-.253.175.43.068 3.93 5.396 1.353-.986-3.929-5.394 2.8-1.975 13.459 1.819 1.078-.785-13.429-1.815.976-.688-.116-.16-1.169.806-1.836-.248-1.078.786 1.786.241-2.624 1.81-.903-1.241zm.699-.239l.37.508.555-.383-.925-.125z"
          fill="#30180E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M164.073 182.781l-2.494 1.816 1.126 1.547-1.428.985 2.423.381 3.066 4.209 2.494-1.816-3.038-4.171 1.469-1.035 11.871 1.336 3.117-2.271-11.952-1.345-.876-1.203-1.376.95-2.686-.303-1.559 1.136-.157-.216z"
          fill="#FCECDD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M167.691 184.697l11.871 1.336 3.117-2.271-11.952-1.345-.876-1.203-1.376.95-2.686-.303-1.559 1.136-.157-.216-2.494 1.816 1.126 1.547-1.428.985 2.423.381 3.066 4.209 2.494-1.816-3.038-4.171 1.469-1.035zm.583 5.051l-3.044-4.179 2.274-1.604 11.864 1.336 1.428-1.041-10.457-1.176-.654-.898-1.027.708-2.676-.301-1.907 1.39-.157-.216-1.353.985 1.137 1.561-.57.393.967.152 2.822 3.875 1.353-.985z"
          fill="#30180E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M179.821 172.631l2.805-2.043 2.029.174 1.874-1.365 1.1 1.511-.138.097 10.656.913-.624.454-2.805 2.043-4.966-.594-5.346-.64-1.348.95 3.509 4.817-2.494 1.817-3.537-4.857-2.424-.381 1.403-1.021-.629-.863 1.325-.965-.39-.047z"
          fill="#FCECDD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M184.655 170.762l-2.029-.174-2.805 2.043.39.047-1.325.965.629.863-1.403 1.021 2.424.381 3.537 4.857 2.494-1.817-3.509-4.817 1.348-.95 10.312 1.234 3.429-2.497-10.656-.913.138-.097-1.1-1.511-1.874 1.365zm1.719-.379l-1.517 1.104-2.028-.173-1.141.83.39.047-2.206 1.607.628.863-.595.434 1.029.161 3.294 4.523 1.353-.986-3.515-4.826 2.155-1.519 10.305 1.234 1.678-1.222-10.7-.917 1.133-.799-.263-.361z"
          fill="#30180E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M196.965 160.143l2.806-2.043 1.558.187 1.564-1.14.787 1.409-.014.01 11.002 1.318-2.806 2.043-10.923-1.308-1.573 1.184 3.88 5.327-2.494 1.816-3.851-5.287-2.269-.495 1.247-.908-.314-.431 2.18-1.588-.78-.094zm1.306.157l.102.14.151-.11-.253-.03z"
          fill="#FCECDD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M201.329 158.287l-1.558-.187-2.806 2.043.78.094-2.18 1.588.314.431-1.247.908 2.269.495 3.851 5.287 2.494-1.816-3.88-5.327 1.573-1.184 10.923 1.308 2.806-2.043-11.002-1.318.014-.01-.787-1.409-1.564 1.14zm1.336-.101l-1.144.834-1.559-.186-1.129.822 1.558.187-2.173 1.583-.314-.432-1.353.986.314.431-.547.399.995.216 3.594 4.935 1.353-.986-3.873-5.318 2.354-1.772 10.93 1.309 1.129-.823-10.961-1.313.925-.696-.099-.176zm-4.141 2.144l-.151.11-.102-.14.253.03z"
          fill="#30180E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215.046 146.645l-2.494 1.816.132.18-1.377 1.058 2.424.381h.001l4.479 6.15 2.494-1.816-4.452-6.112 1.753-1.236 7.284.872 4.965.595 2.806-2.044-12.282-1.471-1.213-1.665-4.435 3.408-.085-.116z"
          fill="#FCECDD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M212.552 148.461l.132.18-1.377 1.058 2.424.381 4.48 6.15 2.494-1.816-4.452-6.112 1.753-1.236 12.249 1.467 2.806-2.044-12.282-1.471-1.213-1.665-4.435 3.408-.085-.116-2.494 1.816zm2.339-.83l-1.353.985.121.165-.619.476.518.081.335-.235 4.473 6.141 1.353-.985-4.458-6.121 2.56-1.805 12.243 1.466 1.129-.823-10.803-1.294-.968-1.329-4.435 3.408-.096-.13z"
          fill="#30180E"
        />
        <path
          d="M228.906 133.908l2.493-1.817 5.974 8.201-2.494 1.817-5.973-8.201z"
          fill="#FCECDD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M231.244 133.077l-1.353.986 5.143 7.06 1.353-.986-5.143-7.06zm-2.338.831l5.973 8.201 2.494-1.817-5.974-8.201-2.493 1.817z"
          fill="#30180E"
        />
        <path
          d="M105.198 235.903l131.861-96.043 2.358 3.237-131.861 96.044-2.358-3.238z"
          fill="#FCECDD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M236.904 140.846l-130.72 95.212 1.527 2.097 130.72-95.213-1.527-2.096zm-131.706 95.057l2.358 3.238 131.861-96.044-2.358-3.237-131.861 96.043z"
          fill="#30180E"
        />
        <path
          d="M168.311 182.705c-28.12 19.363-62.869 36.175-62.869 36.175l-1.403 1.022-36.239 26.23s2.027-6.715 17.434-18.313c15.408-11.599 41.223-19.952 81.645-47.081 40.422-27.13 64.147-48.833 64.147-48.833l1.258 1.727s-35.852 29.709-63.973 49.073z"
          fill="#FCECDD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.245 133.752l-.511.423-.45-.543-1.258-1.726-.476-.521.582-.533 2.113 2.9zm-2.746-1.371c.35-.313.527-.475.527-.475l-.476-.521-.017.015-.051.046-.205.186a270.053 270.053 0 01-4.05 3.554 479.85 479.85 0 01-12.558 10.412c-10.971 8.806-26.991 21.002-47.183 34.554-20.179 13.543-36.712 22.4-50.133 29.323-1.831.945-3.605 1.854-5.324 2.734-10.86 5.565-19.515 9.999-26.22 15.046-7.742 5.829-12.149 10.45-14.627 13.637-1.239 1.594-1.996 2.829-2.448 3.679-.225.425-.374.752-.468.98a5.5 5.5 0 00-.13.341l-.007.023-.003.008v.003l-.001.001c0 .001 0 .002.675.205l-.675-.203-.604 2.001 37.932-27.456 1.354-.986.044-.022.298-.145a469.038 469.038 0 005.482-2.741c3.701-1.881 8.93-4.59 15.037-7.906 12.213-6.629 27.955-15.686 42.044-25.388 14.079-9.695 30.086-21.974 42.568-31.825a1404.33 1404.33 0 0015.408-12.337 1043.623 1043.623 0 005.635-4.609l.307-.253.104-.087-.45-.543s-35.852 29.709-63.972 49.073c-28.12 19.363-62.87 36.176-62.87 36.176l-1.403 1.021-36.239 26.23s.134-.444.596-1.302c1.333-2.477 5.395-8.397 16.838-17.011 6.652-5.007 15.243-9.41 26.112-14.979 14.308-7.331 32.562-16.685 55.533-32.102 36.93-24.786 59.923-45.042 63.62-48.357z"
          fill="#30180E"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_885_7530">
          <path fill="#fff" d="M0 0h300v300H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Pick2;
