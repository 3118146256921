import * as React from "react";

function PICK1(props) {
  return (
    <svg
      width={300}
      height={300}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={150} cy={150} r={118.5} fill="#1C1A1B" stroke="#ABA9A7" />
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={31}
        y={31}
        width={238}
        height={238}
      >
        <circle cx={150} cy={150} r={119} fill="#C4C4C4" />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          d="M74.314 185.195l-19.556 3.4-5.45 6.502 1.763 2 6.893 1.4s9.914 2.502 16.35 2.201c3.347-.157 8.495-1.2 8.495-1.2l21.479 1.2h22.762s22.794 1.715 27.527-5.601c2.043-3.158-.194-2.701-3.169-5.001-6.337-4.901-12.817-7.002-12.817-7.002h-18.915l-18.914 3.701-11.06-1.6H74.314z"
          fill="#1C1A1B"
        />
        <path fill="#CC3B1E" d="M9.876 202.811h278.136v66.894H9.876z" />
        <path
          d="M9.875 90.852l18.936 23.528 28.814 22.505 4.805 28.963 15.152 23.975 37.211 13.043H9.875V90.852z"
          fill="#7D0E03"
        />
        <path
          d="M23.685 124.226l25.319 5.754 8.439 6.138 4.603 28.962-9.974 6.522-4.028-30.497-24.359-16.879z"
          fill="#951D09"
        />
        <path
          d="M9.875 115.787V110.8l14.194 14.002 23.592 16.303-3.453 1.726-22.632-16.303-11.7-10.741z"
          fill="#CC3B1E"
        />
        <path
          d="M9.875 118.855l13.619 30.114 8.439 35.675 24.743 18.222h-46.8v-84.011z"
          fill="#30180E"
        />
        <path
          d="M23.685 148.777l20.715-5.946-12.85 41.046-7.865-35.1zM32.317 183.877l29.346-18.221 1.15 21.674-30.496-3.453zM56.293 202.482l17.07-7.289 26.661 7.289H56.293z"
          fill="#E34C2D"
        />
        <path
          d="M22.725 148.969l9.015 35.676-15.345 2.877 6.33-38.553zM63.003 187.714l-6.905 14.577-23.592-17.646 30.497 3.069z"
          fill="#951D09"
        />
        <path
          d="M9.875 90.852l18.936 23.528 28.814 22.505 4.805 28.963 15.152 23.975 37.211 13.043H9.875V90.852z"
          fill="#7D0E03"
        />
        <path
          d="M23.685 124.226l25.318 5.754 8.44 6.138 4.603 28.962-9.974 6.522-4.028-30.497-24.36-16.879z"
          fill="#951D09"
        />
        <path
          d="M9.875 115.787V110.8l14.194 14.002 23.592 16.303-3.453 1.726-22.632-16.303-11.7-10.741z"
          fill="#CC3B1E"
        />
        <path
          d="M9.875 118.855l13.619 30.114 8.439 35.675 24.743 18.222h-46.8v-84.011z"
          fill="#30180E"
        />
        <path
          d="M23.685 148.777l20.715-5.946-12.851 41.046-7.864-35.1zM32.317 183.877l29.346-18.221 1.15 21.674-30.496-3.453zM56.292 202.482l17.071-7.289 26.661 7.289H56.292z"
          fill="#E34C2D"
        />
        <path
          d="M22.725 148.969l9.015 35.676-15.345 2.877 6.33-38.553zM63.003 187.714l-6.905 14.577-23.592-17.646 30.497 3.069z"
          fill="#951D09"
        />
        <path
          d="M9.875 155.633l10.594 13.164 16.121 12.591 2.688 16.203 8.478 13.414 20.818 7.297H9.875v-62.669z"
          fill="#7D0E03"
        />
        <path
          d="M17.602 174.306l14.164 3.219 4.722 3.434 2.575 16.204-5.58 3.648-2.253-17.062-13.628-9.443z"
          fill="#951D09"
        />
        <path
          d="M9.875 169.583v-2.79l7.941 7.834 13.2 9.121-1.932.966-12.663-9.121-6.546-6.01z"
          fill="#CC3B1E"
        />
        <path
          d="M9.875 171.301l7.62 16.848 4.721 19.959 13.843 10.195H9.875v-47.002z"
          fill="#30180E"
        />
        <path
          d="M22.43 207.679l16.42-10.195.643 12.126-17.062-1.931zM35.845 218.088l9.55-4.078 14.917 4.078H35.845z"
          fill="#E34C2D"
        />
        <path
          d="M39.599 209.825l-3.863 8.156-13.2-9.873 17.063 1.717z"
          fill="#951D09"
        />
        <path
          d="M9.875 155.633l10.594 13.164 16.121 12.591 2.688 16.203 8.478 13.414 20.818 7.297H9.875v-62.669z"
          fill="#7D0E03"
        />
        <path
          d="M17.602 174.305l14.164 3.219 4.722 3.434 2.575 16.204-5.58 3.648-2.253-17.062-13.628-9.443z"
          fill="#951D09"
        />
        <path
          d="M9.875 169.583v-2.79l7.941 7.834 13.2 9.121-1.932.966-12.663-9.121-6.546-6.01z"
          fill="#CC3B1E"
        />
        <path
          d="M9.875 171.3l7.62 16.848 4.721 19.959 13.843 10.195H9.875V171.3z"
          fill="#30180E"
        />
        <path
          d="M22.43 207.679l16.42-10.195.643 12.126-17.062-1.931zM35.845 218.088l9.55-4.078 14.916 4.078H35.845z"
          fill="#E34C2D"
        />
        <path
          d="M39.599 209.824l-3.864 8.156-13.199-9.873 17.063 1.717z"
          fill="#951D09"
        />
        <path
          d="M284.029 88.035l-21.902 27.214-33.328 26.03-5.558 33.5-17.526 27.732-43.04 15.086h121.354V88.035z"
          fill="#7D0E03"
        />
        <path
          d="M268.057 126.638l-29.285 6.655-9.761 7.1-5.325 33.499 11.536 7.543 4.659-35.274 28.176-19.523z"
          fill="#951D09"
        />
        <path
          d="M284.029 116.875v-5.768l-16.417 16.195-27.288 18.858 3.993 1.996 26.179-18.857 13.533-12.424z"
          fill="#CC3B1E"
        />
        <path
          d="M284.029 120.425l-15.752 34.831-9.761 41.265-28.619 21.076h54.132v-97.172z"
          fill="#30180E"
        />
        <path
          d="M268.057 155.035l-23.96-6.877 14.864 47.476 9.096-40.599zM258.072 195.633l-33.944-21.076-1.331 25.07 35.275-3.994zM230.342 217.153l-19.745-8.43-30.838 8.43h50.583z"
          fill="#E34C2D"
        />
        <path
          d="M269.166 155.257l-10.427 41.265 17.748 3.328-7.321-44.593zM222.576 200.07l7.987 16.861 27.288-20.41-35.275 3.549z"
          fill="#951D09"
        />
        <path
          d="M284.029 88.035l-21.902 27.214-33.328 26.03-5.558 33.5-17.526 27.732-43.04 15.086h121.354V88.035z"
          fill="#7D0E03"
        />
        <path
          d="M268.057 126.637l-29.285 6.656-9.761 7.099-5.325 33.5 11.536 7.543 4.659-35.275 28.176-19.523z"
          fill="#951D09"
        />
        <path
          d="M284.029 116.875v-5.768l-16.417 16.195-27.288 18.858 3.993 1.996 26.179-18.857 13.533-12.424z"
          fill="#CC3B1E"
        />
        <path
          d="M284.029 120.425l-15.752 34.831-9.761 41.264-28.619 21.076h54.132v-97.171z"
          fill="#30180E"
        />
        <path
          d="M268.057 155.035l-23.96-6.878 14.864 47.477 9.096-40.599zM258.072 195.633l-33.944-21.076-1.331 25.07 35.275-3.994zM230.342 217.153l-19.745-8.43-30.838 8.43h50.583z"
          fill="#E34C2D"
        />
        <path
          d="M269.166 155.256l-10.427 41.265 17.748 3.328-7.321-44.593zM222.577 200.07l7.986 16.861 27.288-20.41-35.274 3.549z"
          fill="#951D09"
        />
      </g>
      <rect
        x={-0.5}
        y={0.5}
        width={93.355}
        height={187.71}
        rx={9.5}
        transform="matrix(-1 0 0 1 153.929 44.379)"
        stroke="#F4DAC3"
      />
      <path
        d="M63.187 56.491v164.485a9.5 9.5 0 009.5 9.5h70.13a9.5 9.5 0 009.5-9.5V56.491c0-5.246-4.254-9.5-9.5-9.5h-9.364a2.317 2.317 0 00-2.317 2.317 3.316 3.316 0 01-3.316 3.316H88.388a3.317 3.317 0 01-3.317-3.316 2.317 2.317 0 00-2.317-2.317H72.686a9.5 9.5 0 00-9.5 9.5z"
        fill="#262425"
        stroke="#FC6947"
      />
      <path
        d="M196.042 163.601l-6.337-56.331-57.036 6.337 6.69 57.036 56.683-7.042z"
        fill="#fff"
        stroke="#EEE"
      />
      <path
        d="M191.817 149.519l-4.224-39.432-51.755 5.985 4.929 39.432 51.05-5.985z"
        fill="#ED5840"
      />
      <path
        d="M158.385 114.464a5.791 5.791 0 011.409-.012 5.431 5.431 0 012.819 1.178c1.616 1.317 2.089 2.518 2.302 4.249.167 1.351-.553 2.588-1.625 3.221-.579.326-1.443.39-2.057.162-.308-.114-.546-.288-.695-.514-.146-.227-.22-.511-.155-.922l.663-4.155c.104-.65-.466-.682-.558-.26l-.932 4.284c-.093.581.02 1.11.282 1.513.265.404.665.678 1.102.839a3.356 3.356 0 002.763-.217 4.058 4.058 0 001.677-1.8c2.03 6.717 4.977 6.453 9.512 9.628 3.464 2.421 4.829 7.018 3.721 10.584-.441 1.419.782 1.863 1.378.373 1.084-2.697.888-5.561-.549-7.956-.545-.907-.042-2.141.866-2.191.432-.022.846.239 1.084.682 2.347 4.245 2.025 9.077.573 11.407-.728 1.164-1.924 1.803-3.19 1.742-.557-.028-1.367-.209-2.218-.531a4.713 4.713 0 01-2.556 1.061l-6.471.667c-.018-1.246.712-2.52 1.759-2.681.301-.051.448-.446.28-.748l-2.331-4.101a2.993 2.993 0 01-.306-2.317c.197-.745.689-1.401 1.267-1.899.593-.508.119-1.277-.481-.828a4.459 4.459 0 00-1.594 2.41 4.217 4.217 0 00.414 3.159l2.098 3.687a2.733 2.733 0 00-.829.556c-1.631-.317-2.694-.904-3.454-1.746l-.413 4.879-5.53.57c-.012-1.237.708-2.478 1.747-2.585.196-.02.348-.192.37-.418.207-1.967.319-3.674.088-5.919-.047-.458-.561-.453-.569-.068-.047 1.977.021 3.716-.347 5.647-1.529.183-2.207 1.837-2.133 3.429l-2.816.291c-.179-1.735.825-3.27 2.267-3.547 1.508-8.98-.262-8.508-.253-17.717.002-1.001-.53-1.831-1.685-2.25l-3.131-1.142c-2.05-.649-2.321-1.868-2.528-2.948-.24-1.263.321-1.975 1.247-2.07 2.548-.263 2.589-.717 3.534-2.061 1.172-1.666 2.726-2.424 4.184-2.615z"
        fill="#fff"
      />
      <path
        d="M164.355 171.347l4.929-1.056h1.761l-.704-.704-1.761-3.873-1.056.352-.352 1.056-6.689 1.056 3.872 3.169zM170.341 112.199l4.577 2.112 3.169-.352 1.76-3.168v-3.521H172.101l-1.76 1.76h1.76l1.057 1.409h-1.761l-1.056 1.76z"
        fill="#EFBDC5"
      />
      <ellipse
        rx={61.26}
        ry={13.027}
        transform="matrix(-1 0 0 1 188.024 246.115)"
        fill="#30180E"
      />
      <path
        d="M187.242 234.928a4.69 4.69 0 00-2.047-.396l-123.619 4.09c-3.536.117-4.553 4.891-1.371 6.438.474.231.996.348 1.524.342l123.678-1.49c5.043-.061 6.45-6.95 1.835-8.984z"
        fill="#30180E"
      />
      <path
        d="M195.339 123.466c4.609 1.33 29.221 5.281 29.221 5.281s4.631-1.13 4.584-4.567c-.026-1.944-2.64-6.25-4.582-6.347-9.927-.498-26.055-1.76-26.055-1.76l-19.012-5.634s-2.179-.664-3.169.705c-.84 1.163-.917 3.423-.917 3.423s16.579 7.932 19.93 8.899z"
        fill="#FCECDD"
      />
      <path
        d="M216.11 107.975l-1.408 9.154-2.817 1.057-6.337 2.112.704-6.689-5.281-1.057h-1.056l-2.464-5.281 1.408-10.914s2.443-2.493 4.577-3.168c2.113-.67 5.281 0 5.281 0s5.61 2.39 7.393 5.28c.914 1.48 1.409 3.521 1.409 3.521l-1.409 5.985z"
        fill="#EFBDC5"
        stroke="#EFBDC5"
      />
      <path
        d="M205.196 204.09l14.435 32.743s-2.445 1.627-4.225 2.112c-2.387.651-6.337 0-6.337 0l-14.435-32.742-3.521-23.237-15.843 21.124-4.929 36.263s-1.76 3.169-4.929 2.465c-3.437-.764-4.577-2.465-4.577-2.465l3.169-40.84 11.266-24.645 5.633-9.506h29.574l-1.408 4.577-3.873 34.151z"
        fill="#FCECDD"
      />
      <path
        d="M228.433 131.564s-1.238 6.561-4.578 11.97c-2.21 3.581-4.124 4.766-6.689 8.45-3.335 4.79-5.985 13.379-5.985 13.379s-6.995 1.389-11.618 1.76c-7.463.599-19.012-1.76-19.012-1.76L189 144.59l7.042-20.772 17.955-6.337s4.577-.704 9.858 0c2.513.335 3.855 2.633 4.93 4.929 1.108 2.366-.352 9.154-.352 9.154zM220.335 243.17l-1.056-5.633-10.21 1.408-1.056.352s-5.156.555-5.633 1.761c-.752 1.901 3.872 3.521 3.872 3.521l4.225 1.056s5.281 1.76 8.098 1.056c1.789-.447 1.76-3.521 1.76-3.521z"
        fill="#FCECDD"
      />
      <path
        d="M194.282 155.152c2.83-3.873 8.45-22.885 8.45-22.885s.78-7.314-2.464-8.449c-1.836-.642-4.172-1.087-4.929.704-3.873 9.154-9.506 24.645-9.506 24.645l-17.604 17.603 1.056 3.169 3.521 1.056s19.418-13.026 21.476-15.843z"
        fill="#FCECDD"
      />
      <path
        d="M202.38 125.578s-.777-1.682-1.761-2.112c-.881-.386-1.515-.515-2.464-.353-.857.147-2.113 1.057-2.113 1.057"
        stroke="#9E8973"
      />
      <path
        d="M210.123 112.552H215.757l2.112-9.858s.431-2.423 0-3.873c-.72-2.423-4.224-3.52-4.224-3.52s-2.551-2.27-4.578-2.817c-1.858-.502-5.633 0-5.633 0s-2.919 2.074-4.929 3.168c-3.251 1.77-8.802 3.521-8.802 3.521s1.861 2.229 3.521 2.817c1.685.596 2.952-.745 4.577 0 2.295 1.051.985 4.367 3.169 5.633 1.564.907 2.914-.007 4.577.704 2.236.956 4.576 4.225 4.576 4.225z"
        fill="#9F635F"
      />
      <path
        d="M171.75 246.339l-1.057-5.281-8.801-.704-2.465 1.056h-3.521l-1.408 2.112 1.056 2.465 10.21 2.816 3.873.704 2.113-3.168z"
        fill="#FCECDD"
      />
      <path
        d="M202.732 127.338v5.281l-5.986 16.9-2.816 5.985-5.985 4.929-14.787 10.21M215.758 117.48s-3.226.83-5.281 1.409c-3.6 1.013-9.154 2.816-9.154 2.816l-4.929 1.761-.704.704"
        stroke="#9E8973"
      />
    </svg>
  );
}

export default PICK1;
